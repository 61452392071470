@import "../../variables";

.loader {
  width: 100%;
  height: 100%;
  &_container {
    position: relative;
    z-index: 200;
    width: 100%;
    height: 100%;
    &_spinner {
      width: 100%;
      height: 100%;
      display: block;
      min-width: 1rem;
      min-height: 1rem;
      border-radius: 50%;
      border: 0.5rem solid $color-white;
      border-color: $color-secondary transparent $color-secondary transparent;
      animation: rotationAnimation 1.1s linear infinite;
    }
    &_text {
      color: $color-white;
      text-align: center;
      font-size: 1.2rem;
      margin-top: 2rem;
    }
  }
}

@keyframes rotationAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
