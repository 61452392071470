@import "../../variables";

.layout {
  overflow: hidden;
  position: relative;
  main {
    margin-top: $height-header;
    min-height: $height-main;
    font-size: $font-default;
    @media screen and (max-height: 500px) {
      margin-top: 0;
    }
  }
  &_loader {
    top: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    .loader_container {
      width: 10rem;
      height: 10rem;
    }
  }
}
