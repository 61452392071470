@import "../../variables.scss";

.image-selector {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &_container {
    height: 5rem;
    margin: 0.1rem;
    cursor: pointer;
    img {
      height: 100%;
    }
    &--selected {
      position: relative;
      &::after {
        position: absolute;
        content: " ";
        background-color: rgba($color-success, 0.4);
        border-radius: 10%;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        pointer-events: none;
      }
    }
  }
}
