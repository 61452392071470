@import "../../variables";

.input {
  width: 100%;
  padding: 0.5rem 0.2rem;
  border: 1px solid #ccc;
  border-radius: 0.3rem;
  &:focus {
    outline-color: $color-success;
  }
}
