@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 62.5%;
  font-family: "Josefin Sans", sans-serif; }

body {
  box-sizing: border-box;
  background-color: #fff; }

.setup-page {
  padding: 1rem;
  display: flex;
  justify-content: center; }
  .setup-page_groups {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 60rem;
    margin-top: 3%; }
    .setup-page_groups_ipaddress {
      margin-bottom: 2rem;
      width: 100%; }
      .setup-page_groups_ipaddress_input {
        padding: 1rem; }
    .setup-page_groups_keyboard {
      margin-bottom: 2rem; }
      .setup-page_groups_keyboard_keys {
        padding: 1.5rem 0.5rem; }
      .setup-page_groups_keyboard_note {
        padding: 0 1rem 1rem 1rem;
        font-size: 1.2rem;
        color: #9b9b9b; }
    .setup-page_groups_speed {
      width: 100%;
      margin-bottom: 3rem; }
      .setup-page_groups_speed_slider {
        padding: 1rem; }
        .setup-page_groups_speed_slider .MuiSlider-root {
          color: #3a3a3a; }
          .setup-page_groups_speed_slider .MuiSlider-root .MuiSlider-valueLabel {
            font-size: 1.4rem; }
    .setup-page_groups_save {
      align-self: flex-end; }

.image-selector {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .image-selector_container {
    height: 5rem;
    margin: 0.1rem;
    cursor: pointer; }
    .image-selector_container img {
      height: 100%; }
    .image-selector_container--selected {
      position: relative; }
      .image-selector_container--selected::after {
        position: absolute;
        content: " ";
        background-color: rgba(74, 128, 98, 0.4);
        border-radius: 10%;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        pointer-events: none; }

.group-box {
  margin: 0.25rem; }
  .group-box_fieldset {
    background-color: #f2f2f2;
    border: 1px solid rgba(17, 97, 155, 0.2);
    border-radius: 0.5rem; }
    .group-box_fieldset_box {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap; }
    .group-box_fieldset_title {
      margin-left: 0.7rem;
      font-size: 1.5rem;
      color: #11619b;
      font-family: "Roboto", sans-serif; }

.input {
  width: 100%;
  padding: 0.5rem 0.2rem;
  border: 1px solid #ccc;
  border-radius: 0.3rem; }
  .input:focus {
    outline-color: #4a8062; }

.button {
  height: 100%;
  width: 100%;
  background-color: #11619b;
  border: none;
  color: #fff;
  outline: none;
  cursor: pointer;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  -webkit-transition: all 0.1s;
  transition: all 0.1s; }
  .button:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  .button:active {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); }
  .button:disabled {
    color: #9b9b9b;
    background-color: rgba(155, 155, 155, 0.5);
    cursor: not-allowed;
    -webkit-transform: none;
            transform: none; }
  .button_loader {
    display: flex;
    justify-content: center; }
  .button--transparent {
    background-color: transparent;
    padding: 0; }
    .button--transparent:disabled {
      background-color: transparent; }
  .button--info {
    background-color: #3d4d80; }
  .button--danger {
    background-color: #8d4040; }

.loader {
  width: 100%;
  height: 100%; }
  .loader_container {
    position: relative;
    z-index: 200;
    width: 100%;
    height: 100%; }
    .loader_container_spinner {
      width: 100%;
      height: 100%;
      display: block;
      min-width: 1rem;
      min-height: 1rem;
      border-radius: 50%;
      border: 0.5rem solid #fff;
      border-color: #3a3a3a transparent #3a3a3a transparent;
      -webkit-animation: rotationAnimation 1.1s linear infinite;
              animation: rotationAnimation 1.1s linear infinite; }
    .loader_container_text {
      color: #fff;
      text-align: center;
      font-size: 1.2rem;
      margin-top: 2rem; }

@-webkit-keyframes rotationAnimation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotationAnimation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.55); }

.play-page {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  touch-action: none;
  height: calc(100vh - 6rem);
  position: relative; }
  @media screen and (max-height: 500px) {
    .play-page {
      height: calc(6rem + calc(100vh - 6rem)); } }
  .play-page_camera {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #3a3a3a;
    display: flex;
    align-items: center;
    justify-content: center; }
    .play-page_camera_none {
      width: 20%;
      max-width: 20rem;
      min-width: 10rem;
      margin-top: -8rem; }
      .play-page_camera_none_image svg {
        fill: rgba(255, 255, 255, 0.2); }
    .play-page_camera_ok {
      max-width: 100%; }
      .play-page_camera_ok_image {
        width: 100%; }
        .play-page_camera_ok_image img {
          width: 100%;
          color: #9b9b9b;
          font-size: 1.3rem; }
      .play-page_camera_ok_disconnect {
        position: fixed;
        bottom: 2rem;
        left: 2rem;
        width: 3rem; }
  .play-page_remote {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.remote_keyboard--selected {
  position: relative;
  -webkit-transform: scale(0.9);
          transform: scale(0.9); }
  .remote_keyboard--selected::after {
    position: absolute;
    content: " ";
    background-color: rgba(61, 77, 128, 0.4);
    border-radius: 0.9rem;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    border: 2px solid rgba(58, 58, 58, 0.5); }

.remote_keyboard--disabled {
  cursor: not-allowed !important;
  position: relative;
  opacity: .8; }
  .remote_keyboard--disabled::after {
    position: absolute;
    content: " ";
    background-color: rgba(155, 155, 155, 0.9);
    border-radius: 0.9rem;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none; }

.remote_keyboard_key {
  pointer-events: all;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  border-radius: 0.9rem;
  box-shadow: 0 0 5px #000;
  height: 7rem;
  width: 7rem;
  overflow: hidden; }
  @media screen and (max-width: 900px) {
    .remote_keyboard_key {
      height: 6rem;
      width: 6rem; } }
  @media screen and (max-width: 700px) {
    .remote_keyboard_key {
      height: 5rem;
      width: 5rem; } }
  .remote_keyboard_key img {
    height: 100%;
    width: 100%; }

.remote_keyboard_left {
  position: fixed;
  top: 6rem;
  left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem; }
  .remote_keyboard_left .remote_keyboard_key {
    margin-bottom: 1rem;
    margin-right: 1rem;
    max-height: 6rem;
    max-width: 6rem; }
  @media screen and (max-height: 500px) {
    .remote_keyboard_left {
      top: 0; } }

.remote_keyboard_right {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 30rem;
  height: 30rem; }
  @media screen and (max-width: 900px) {
    .remote_keyboard_right {
      height: 22rem;
      width: 22rem; } }
  @media screen and (max-width: 700px) {
    .remote_keyboard_right {
      width: 17rem;
      height: 17rem; } }
  .remote_keyboard_right_group1 {
    pointer-events: none;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 0; }
    @media screen and (max-width: 700px) {
      .remote_keyboard_right_group1 {
        padding: 1rem 0; } }
  .remote_keyboard_right_group2 {
    pointer-events: none;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem; }
    @media screen and (max-width: 700px) {
      .remote_keyboard_right_group2 {
        padding: 0 1rem; } }

.MuiListItem-root .MuiListItemText-primary {
  min-height: 1rem !important;
  font-size: 1.6rem !important;
  color: #3a3a3a; }

.MuiListItem-root .MuiSvgIcon-root {
  font-size: 2rem !important;
  color: #3a3a3a; }

.layout {
  overflow: hidden;
  position: relative; }
  .layout main {
    margin-top: 6rem;
    min-height: calc(100vh - 6rem);
    font-size: 1.6rem; }
    @media screen and (max-height: 500px) {
      .layout main {
        margin-top: 0; } }
  .layout_loader {
    top: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center; }
    .layout_loader .loader_container {
      width: 10rem;
      height: 10rem; }

.header {
  background-color: #11619b;
  position: fixed;
  width: 100vw;
  z-index: 1000;
  box-shadow: 0 0 5px #3a3a3a; }
  @media screen and (max-height: 500px) {
    .header {
      margin-top: calc(-1 * 6rem);
      box-shadow: none; } }
  .header_container {
    padding: 0 2rem;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .header_container_left {
      display: flex;
      align-items: center; }
      .header_container_left_logo {
        height: 4rem; }
        .header_container_left_logo .icon-button {
          cursor: default; }
          .header_container_left_logo .icon-button:hover {
            -webkit-transform: none;
                    transform: none; }
      .header_container_left_text {
        display: flex;
        flex-direction: column;
        margin-left: 1rem; }
        .header_container_left_text_title {
          font-size: 2rem;
          color: #fff; }
        .header_container_left_text_subtitle {
          margin-top: 0.2rem;
          color: rgba(255, 255, 255, 0.6); }
    .header_container_right_button {
      color: #fff; }
      @media screen and (max-height: 500px) {
        .header_container_right_button {
          position: fixed;
          z-index: 100;
          right: 1rem;
          top: 1rem;
          background-color: #11619b;
          box-shadow: 1px 1px 5px #000;
          border-radius: 50%;
          padding: 0.5rem;
          display: block; } }
      .header_container_right_button svg {
        font-size: 2.5rem; }

.icon-button {
  border: none;
  height: 100%;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center; }
  .icon-button:hover {
    -webkit-transform: scale(1.3) rotate(360deg);
            transform: scale(1.3) rotate(360deg); }
  .icon-button svg {
    height: 100%; }

.toaster {
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  visibility: hidden;
  width: 90%;
  max-width: 60rem;
  height: 7rem;
  background-color: #3d4d80;
  position: fixed;
  z-index: 10;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  bottom: 0;
  opacity: 0; }
  .toaster_close {
    cursor: pointer;
    position: absolute;
    right: 0.9rem;
    top: 0.4rem; }
    .toaster_close button {
      font-size: 1.8rem; }
    .toaster_close:hover {
      -webkit-transform: scale(1.3);
              transform: scale(1.3); }
  .toaster_message {
    color: #fff;
    height: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem; }
  .toaster--show {
    visibility: visible;
    bottom: 4rem;
    opacity: 1; }
  .toaster--success {
    background-color: #4a8062; }
  .toaster--warning {
    background-color: #80804a; }
  .toaster--error {
    background-color: #833c3c; }
  .toaster--danger {
    background-color: #8d4040; }
  .toaster--info {
    background-color: #3d4d80; }

