@import "../../variables.scss";

.play-page {
  user-select: none;
  touch-action: none;
  height: $height-main;
  position: relative;
  @media screen and (max-height: 500px) {
    height: calc(#{$height-header} + #{$height-main});
  }
  &_camera {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-secondary;
    display: flex;
    align-items: center;
    justify-content: center;

    &_none {
      width: 20%;
      max-width: 20rem;
      min-width: 10rem;
      margin-top: -8rem;
      &_image {
        svg {
          fill: rgba($color-white, 0.2);
        }
      }
    }
    &_ok {
      max-width: 100%;
      &_image {
        width: 100%;
        img {
          width: 100%;
          color: $color-grey;
          font-size: 1.3rem;
        }

      }
      &_disconnect {
        position: fixed;
        bottom: 2rem;
        left: 2rem;
        width: 3rem;

      }
    }
  }
  &_remote {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
