@import "../../../variables";

.header {
  background-color: $color-primary;
  position: fixed;
  width: 100vw;
  z-index: 1000;
  box-shadow: 0 0 5px $color-secondary;

  @media screen and (max-height: 500px) {
    margin-top: calc(-1 * #{$height-header});
    box-shadow: none;
  }
  &_container {
    padding: 0 2rem;
    height: $height-header;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &_left {
      display: flex;
      align-items: center;
      &_logo {
        height: 4rem;
        .icon-button {
          cursor: default;
          &:hover {
            transform: none;
          }
        }
      }
      &_text {
        display: flex;
        flex-direction: column;
        margin-left: 1rem;
        &_title {
          font-size: 2rem;
          color: $color-white;
        }
        &_subtitle {
          margin-top: 0.2rem;
          color: rgba($color-white, 0.6);
        }
      }
    }
    &_right {
      &_button {
        color: $color-white;

        @media screen and (max-height: 500px) {
          position: fixed;
          z-index: 100;
          right: 1rem;
          top: 1rem;
          background-color: $color-primary;
          box-shadow: 1px 1px 5px $color-black;
          border-radius: 50%;
          padding: 0.5rem;
          display: block;
        }

        svg {
          font-size: 2.5rem;
        }
      }
    }
  }
}
