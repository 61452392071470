@import "../../variables";

.icon-button {
  border: none;
  height: 100%;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    transform: scale(1.3) rotate(360deg);
  }

  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 100%;
  }
}
