@import "../../variables.scss";

.setup-page {
  padding: 1rem;
  display: flex;
  justify-content: center;
  &_groups {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 60rem;
    margin-top: 3%;
    &_ipaddress {
      margin-bottom: 2rem;
      width: 100%;
      &_input {
        padding: 1rem;
      }
    }
    &_keyboard {
      margin-bottom: 2rem;
      &_keys {
        padding: 1.5rem 0.5rem;
      }
      &_note {
        padding: 0 1rem 1rem 1rem;
        font-size: 1.2rem;
        color: $color-grey;
      }
    }
    &_speed {
      width: 100%;
      margin-bottom: 3rem;
      &_slider {
        padding: 1rem;
        .MuiSlider-root {
          color: $color-secondary;
          .MuiSlider-valueLabel {
            font-size: 1.4rem;
          }
        }
      }
    }
    &_save {
      align-self: flex-end;
    }
  }
}
